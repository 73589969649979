import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { uniq } from 'lodash'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './item'

export interface Props {
  subtitle?: string
  title?: string
  items: ItemProps[]
}

export const TimelineSliderMobile = memo(function TimelineSliderMobile({
  subtitle,
  title,
  items,
}: Props) {
  if (!items) {
    return null
  }

  return (
    <Container className="blended">
      <Inner row={false} dial={5}>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        {subtitle ? (
          <FadeInUp>
            <Subtitle>{subtitle}</Subtitle>
          </FadeInUp>
        ) : null}
      </Inner>

      <Items row={false} dial={4}>
        <Line />
        {uniq(items).map((item, index) => {
          return <Item key={index} {...item} />
        })}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  margin-top: 6rem;
  padding-bottom: 6rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryDark};
`

const Inner = styled(FlexBox)`
  padding-top: 5.25rem;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  line-height: 2.4375rem;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  margin-bottom: 4.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 2.25rem;
  letter-spacing: 0.05em;
  line-height: 2.4375rem;
  text-transform: uppercase;
`

const Items = styled(FlexBox)`
  position: relative;
  margin-left: 3.75rem;
  margin-right: 1.5rem;
`

const Line = styled.div`
  position: absolute;
  top: 0;
  left: -2.25rem;
  height: 100%;
  width: 0.0625rem;
  background-color: ${({ theme }) => theme.colors.variants.neutralDark2};

  &::after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 8rem;
    width: 0.0625rem;
    background-color: ${({ theme }) => theme.colors.variants.primaryLight};

    @media (max-width: 480px) {
      height: 10rem;
    }
  }
`
