import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  description?: string
  title?: string
}

export const Item = memo(function Item({
  className,
  description,
  title,
}: Props) {
  return (
    <Container className={className}>
      <Inner dial={4}>
        {title ? <Title>{title}</Title> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </Inner>
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  margin-right: 2.25rem;
  padding-right: 17.875rem;

  &:last-of-type {
    margin-right: 0;
  }

  &:after {
    content: '';
    width: 15.9375rem;
    height: 0.0625rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    position: absolute;
    top: 2.5rem;
    right: 0;
  }
`

const Inner = styled(FlexBox)``

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 4.375rem;
  letter-spacing: 0.05em;
  line-height: 4.6875rem;
`

const Description = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;
`
