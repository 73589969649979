import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  className?: string
  description?: string
  title?: string
}

export const Item = memo(function Item({
  className,
  description,
  title,
}: Props) {
  return (
    <Container className={className}>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  &:not(:last-of-type) {
    margin-bottom: 4.25rem;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 1.625rem;
  letter-spacing: 0.05em;
  line-height: 1.8125rem;
`

const Description = styled.div`
  margin-top: 0.375rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;
`
