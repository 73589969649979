import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  description?: string
  title?: string
}

export const Visual = memo(function Visual({
  image,
  description,
  title,
}: Props) {
  return (
    <Container>
      <FadeInUp>
        <Icon src="/logo-dark.svg" alt="Logo" />
      </FadeInUp>

      <Wrapper className="blended" row={false} dial={5}>
        <Blended>
          {image ? (
            <LazyLoadComponent>
              <Background {...image} />
            </LazyLoadComponent>
          ) : null}
          {title ? (
            <FadeIn>
              <Title>{title}</Title>
            </FadeIn>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Blended>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section``

const Icon = styled.img`
  display: block;
  max-height: 6.25rem;
  margin: 6.25rem auto;

  @media (max-width: 1199px) {
    max-height: 5rem;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  width: 100%;
  height: 77.777vh;
  max-height: 50rem;
  padding: 0 10.833vw;

  @media (max-width: 1199px) {
    padding: 0 2.625rem;
    max-height: 31.25rem;
  }
`

const Blended = styled.div`
  @media (min-width: 1200px) {
    mix-blend-mode: difference;

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
      mix-blend-mode: luminosity;
    }
  }
`

const Background = styled(Image)`
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    backface-visibility: hidden;
    opacity: 0.5;
  }
`

const Title = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 18.75rem;
  letter-spacing: 0.05em;
  line-height: 13.9375rem;
  text-align: center;
  text-transform: uppercase;
  opacity: 0.15;
  z-index: 2;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: 1199px) {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    margin-bottom: 1.25rem;
    font-weight: 300;
    font-size: 0.8125rem;
    letter-spacing: 0.3em;
    line-height: 1.125rem;
    opacity: 1;
  }
`

const Description = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 200;
  font-size: 2.25rem;
  line-height: 2.8125rem;
  text-align: center;
  z-index: 2;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 2.1875rem;
  }
`
