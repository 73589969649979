import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { uniq } from 'lodash'
import React, { memo, useEffect, useRef } from 'react'

import { Item, Props as ItemProps } from './item'

export interface Props {
  subtitle?: string
  title?: string
  items: ItemProps[]
}

export const TimelineSlider = memo(function TimelineSlider({
  subtitle,
  title,
  items,
}: Props) {
  if (!items) {
    return null
  }

  const containerRef = useRef<any>()
  const slidesRef = useRef<any>()

  useEffect(() => {
    const containerRect = containerRef.current.getBoundingClientRect()
    const slideRect = slidesRef.current.getBoundingClientRect()

    const containerTop = containerRef.current.offsetTop
    const containerHeight = containerRect.height
    const slideWidth = slideRect.width

    const scrollDistance = containerHeight - window.innerHeight
    const slideDistance = slideWidth - window.innerWidth

    const getProgress = (start: any, distance: any, scroll: any) => {
      return Math.min(1, Math.max(0, (scroll - start) / distance))
    }

    const handleScroll = () => {
      const scrollPos = window.scrollY
      const progress = getProgress(containerTop, scrollDistance, scrollPos)

      slidesRef.current.style.transform = `translateX(-${
        slideDistance * progress
      }px)`
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <Container
      className="blended"
      ref={containerRef}
      style={{ height: 100 * items.length + 'vh' }}
    >
      <Sticky>
        <Background />
        <Inner>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {subtitle ? (
            <FadeInUp>
              <Subtitle>{subtitle}</Subtitle>
            </FadeInUp>
          ) : null}
        </Inner>

        <Slider ref={slidesRef} style={{ width: 800 * items.length + 'px' }}>
          {uniq(items).map((item, index) => {
            return <Item key={index} {...item} />
          })}
        </Slider>
      </Sticky>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  width: 100%;
  margin-top: 9.375rem;
`

const Sticky = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;

  @media (min-width: 1200px) {
    mix-blend-mode: difference;

    @supports (font: -apple-system-body) and (-webkit-appearance: none) {
      mix-blend-mode: luminosity;
    }
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.variants.primaryDark};
  &:before {
    content: '';
    position: absolute;
    top: 3.5rem;
    right: 3.75rem;
    bottom: 3.5rem;
    left: 3.75rem;
    background-image: url('/pattern-zigzag.svg');
    opacity: 0.05;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 7.8125rem;
  left: 10.833vw;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 3.125rem;
  letter-spacing: 0.05em;
  line-height: 3.4375rem;
  text-transform: uppercase;
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 3.125rem;
  letter-spacing: 0.05em;
  line-height: 3.4375rem;
  text-transform: uppercase;
`

const Slider = styled.div`
  display: flex;
  height: 100vh;
  outline: none;
  padding: 28.125rem 10.833vw 0 37.847vw;
`
