import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Hero } from 'app/components/Hero'
import { IntroHero } from 'app/components/IntroHero'
import { Mosaic } from 'app/components/Mosaic'
import { Review } from 'app/components/Review'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { TimelineSlider } from 'app/components/TimelineSlider'
import { TimelineSliderMobile } from 'app/components/TimelineSliderMobile'
import { Visual } from 'app/components/Visual'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function AboutPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        <SEO {...context.seoProps} />
        {context.structuredDataProps ? (
          <StructuredData
            languageCode={pageContext.languageCode}
            {...context.structuredDataProps}
          />
        ) : null}
        {context.headerProps ? (
          <Header
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            pageID={context.id}
            {...context.headerProps}
          />
        ) : null}
        {context.heroProps ? <Hero {...context.heroProps} /> : null}
        {context.introHeroProps ? (
          <IntroHero {...context.introHeroProps} />
        ) : null}
        {context.visualProps ? <Visual {...context.visualProps} /> : null}
        {context.mosaicProps ? <Mosaic {...context.mosaicProps} /> : null}
        {context.timelineSliderProps ? (
          <>
            <Media greaterThanOrEqual="desktopSmall">
              <TimelineSlider {...context.timelineSliderProps} />
            </Media>
            <Media lessThan="desktopSmall">
              <TimelineSliderMobile {...context.timelineSliderProps} />
            </Media>
          </>
        ) : null}
        {context.reviewProps ? <Review {...context.reviewProps} /> : null}
        {context.bannerCTAProps ? (
          <BannerCTA {...context.bannerCTAProps} />
        ) : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            languagePrefix={context.languagePrefix}
            {...context.footerProps}
          />
        ) : null}
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.main``
