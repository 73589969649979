import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'

export const ButtonScroll = memo(function ButtonScroll() {
  const scrollDown = () => {
    window.scroll({
      top: window.innerHeight + 1,
      left: 0,
      behavior: 'smooth',
    })
  }

  return <Button onClick={scrollDown} />
})

const Button = styled.div`
  height: 4.5rem;
  padding: 1.25rem;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.1875rem;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(-50%);
    width: 0.125rem;
    height: 100%;
  }

  &:before {
    animation: scrolldown 2s cubic-bezier(0.76, 0, 0.3, 1) forwards infinite;
    background: linear-gradient(
      to bottom,
      ${rgba(theme.colors.variants.primaryLight, 1)} 50%,
      ${rgba(theme.colors.variants.primaryLight, 0)} 50%
    );
    background-position: 0 -4.5rem;
    background-size: 100% 200%;
  }

  &:after {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
    opacity: 0.2;
  }

  @keyframes scrolldown {
    0% {
      background-position: 0 -4.5rem;
    }
    35% {
      background-position: 0 0;
    }
    100% {
      background-position: 0 4.5rem;
    }
  }
`
