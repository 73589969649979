import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  accomodation?: string
  description?: string
  label?: string
  title?: string
  user?: string
}

export const Review = memo(function Review({
  accomodation,
  description,
  label,
  title,
  user,
}: Props) {
  return (
    <Container>
      <FadeIn>
        <Wrapper row={false} dial={5}>
          <Top row={false} dial={5}>
            {label ? (
              <FadeInUp>
                <Label>{label}</Label>
              </FadeInUp>
            ) : null}
            <FadeIn>
              <Icon src="/quotation.svg" />
            </FadeIn>
          </Top>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          <Bottom row={false} dial={5}>
            {user ? (
              <FadeInUp>
                <UserClaim>{user}</UserClaim>
              </FadeInUp>
            ) : null}
            {accomodation ? (
              <FadeInUp>
                <Claim>{accomodation}</Claim>
              </FadeInUp>
            ) : null}
          </Bottom>
        </Wrapper>
      </FadeIn>
    </Container>
  )
})

const Container = styled.section`
  margin: 15rem 10.833vw 11.25rem;

  @media (max-width: 1199px) {
    margin: 11rem 1.5rem 7.5rem;
  }

  @media (max-width: 480px) {
    margin: 9rem 1.5rem 7.5rem;
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  max-width: 58.375rem;
  min-height: 22.75rem;
  margin: 0 auto;
  padding: 3.625rem 6.7vw 0;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};

  @media (max-width: 1199px) {
    padding: 2rem 1.875rem 0;
  }

  @media (max-width: 480px) {
    min-height: 25.75rem;
  }
`

const Top = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -60%);
  padding: 0 2.8125rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};
`

const Label = styled.div`
  margin-bottom: 1.5625rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 500;
  font-size: 0.8125rem;
  letter-spacing: 0.15em;
  text-align: center;
  text-transform: uppercase;
`

const Icon = styled.img`
  max-height: 10rem;

  @media (max-width: 1199px) {
    max-height: 7rem;
  }

  @media (max-width: 480px) {
    max-height: 4.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    max-width: 37.5rem;
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Description = styled.div`
  max-width: 36.25rem;
  margin-top: 0.375rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;
  text-align: center;
`

const Bottom = styled(FlexBox)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  padding: 0 2.8125rem;
  background-color: ${({ theme }) => theme.colors.variants.primaryLight};

  @media (max-width: 480px) {
    padding: 0 1.5rem;
  }
`

const UserClaim = styled.div`
  min-width: max-content;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 480px) {
    min-width: 15.625rem;
  }
`

const Claim = styled.div`
  min-width: max-content;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-weight: 300;
  font-size: 0.8125rem;
  letter-spacing: 0.3em;
  line-height: 1.125rem;
  text-align: center;
  text-transform: uppercase;
`
