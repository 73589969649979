import styled from '@emotion/styled'
import { FadeIn } from 'app/components/Common/Animation/FadeIn'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

interface ImagesProps {
  image?: ImageProps
}

export interface Props {
  description?: string
  images?: ImagesProps[]
  subtitle?: string
  title?: string
}

export const Mosaic = memo(function Mosaic({
  description,
  images,
  subtitle,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images?.length < 1) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <FadeIn>
          <Box />
        </FadeIn>
        <FadeInUp>
          <Items row wrap dial={8}>
            {images.map((item, index) => (
              //necessario al posto dell'empty fragment <></> per poter accettare la proprietà "key"
              <React.Fragment key={index}>
                {index === 1 ? (
                  <Inner row={false} dial={5}>
                    {title ? (
                      <FadeInUp>
                        <Title>{title}</Title>
                      </FadeInUp>
                    ) : null}
                    {subtitle ? (
                      <FadeInUp>
                        <Subtitle>{subtitle}</Subtitle>
                      </FadeInUp>
                    ) : null}
                    {description ? (
                      <FadeInUp>
                        <Description
                          dangerouslySetInnerHTML={{ __html: description }}
                        />
                      </FadeInUp>
                    ) : null}
                  </Inner>
                ) : null}
                <LazyLoadComponent>
                  <StyledImage {...item.image} />
                </LazyLoadComponent>
              </React.Fragment>
            ))}
          </Items>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 15rem 10.833vw 0;

  @media (max-width: 1439px) {
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 1199px) {
    margin: 9rem 1.5rem 0;
  }

  @media (max-width: 430px) {
    margin-left: 0;
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  position: relative;
  max-width: 70.5rem;
  margin: 0 auto;
`

const Box = styled.div`
  position: absolute;
  top: -5.625rem;
  left: 50%;
  transform: translateX(-50%);
  width: 77.5%;
  height: 91%;
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight2};

  @media (max-width: 1199px) {
    top: -3rem;
    width: calc(100% - 3rem);
  }
`

const Items = styled(FlexBox)`
  margin-left: -2.1875rem;
  margin-bottom: -2.1875rem;
`

const Inner = styled(FlexBox)`
  max-width: 33.75rem;
  width: calc(50% - 2.1875rem);
  margin-left: 2.1875rem;
  margin-bottom: 2.1875rem;
  padding: 0 2.8125rem 2rem;
  text-align: center;

  @media (max-width: 1199px) {
    max-width: 100%;
    width: 100%;
    padding: 0 3.75rem 0.5rem;
    order: 1;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: 300;
  font-size: 2rem;
  line-height: 2.1875rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.625rem;
    line-height: 1.8125rem;
  }
`

const Description = styled.div`
  margin-top: 2.5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const StyledImage = styled(Image)`
  width: calc(25% - 2.1875rem);
  margin-left: 2.1875rem;
  margin-bottom: 2.1875rem;

  @media (max-width: 1199px) {
    width: calc(33% - 2.1875rem);
  }

  @media (max-width: 767px) {
    width: calc(50% - 2.1875rem);
  }

  img {
    width: 100%;
    max-width: 15.9375rem;
    max-height: 21.25rem;
    aspect-ratio: 255/340;
    object-fit: cover;

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    @media (max-width: 480px) {
      max-width: 12.3125rem;
      max-height: 16.375rem;
    }
  }

  &:nth-of-type(1),
  &:nth-of-type(2) {
    margin-bottom: -3.4375rem;

    @media (max-width: 1199px) {
      margin-bottom: 2.1875rem;
      order: 2;
    }
  }

  &:nth-of-type(3),
  &:nth-of-type(6) {
    @media (max-width: 1199px) {
      margin-bottom: 7.4375rem;
      order: 3;
    }

    @media (max-width: 767px) {
      margin-bottom: 2.1875rem;
    }

    img {
      max-width: 15.9375rem;
      max-height: 15.9375rem;
      aspect-ratio: 1;

      @media (max-width: 1199px) {
        max-width: 100%;
      }

      @media (max-width: 480px) {
        max-width: 12.3125rem;
        max-height: 12.3125rem;
      }
    }
  }

  &:nth-of-type(4) {
    @media (max-width: 1199px) {
      order: 2;
    }
  }

  &:nth-of-type(5) {
    @media (max-width: 1199px) {
      order: 3;
    }
  }

  &:nth-of-type(4),
  &:nth-of-type(5) {
    @media (max-width: 767px) {
      order: 4;
    }
  }
`
